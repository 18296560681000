<template>
	<!-- 项目 - 项目合同情况-->
<div>
	<div class="container">
		<GModule class="g1" style="margin-bottom: 10px;">
			<GTitle :name="proName+'项目合同基本情况'"></GTitle>
			<div class="state-box">
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.nickname}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button v-if="stateArr.length > 0">
						{{stateArr[stateIndex].nickname}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
				<div style="margin-left: 30px;">合同签订时间&nbsp;&nbsp;
					<span style="color: #999999;">{{info.createtime_text}}</span>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本期合计工时&nbsp;&nbsp;
					<span style="color:#999999;">{{info.total_hour}}</span>
				</div>
			</div>
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500}" 
				bordered
				:pagination="false">
			   <template #bodyCell="{ column ,record,index}">
				    <template v-if="column.title === '凭证' && record.imgArr != []">
						<div class="image-w-h">
							<a-image :preview="false" :width="120" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
						</div>
				    </template>
					<template v-if="column.key === 'operation'">
						<div class="caozuo" v-if="record.status == 'wait' || record.status == 'fail'">
							<p @click="updateClick(1,record.id,record.content,index)">编辑</p>
							<!-- <a-popconfirm
							    title="确认是否申请支付?"
							    ok-text="确认"
							    cancel-text="取消"
							    @confirm="payClick(1,record.id,index)">
							    <p>申请支付</p>
							  </a-popconfirm> -->
							  <p @click="payClick(1,record.id,index)">申请支付</p>
						</div>
						<div class="caozuo2" v-else>
							<p>编辑</p>
							<p>申请支付</p>
						</div>
					</template>
			  </template>
			</a-table>
		</GModule>	
		<GModule class="g2">
			<GTitle name="新增/变更情况"></GTitle>
			<div class="time-box">
				<p>剩余免费工时：</p>
				<p>{{info.last_hour}}</p>
			</div>	
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe2" 
				:data-source="data2" 
				:scroll="{ x: 500}" 
				bordered
				:pagination="false">
			    <template #bodyCell="{ column ,record,index}">
				    <template v-if="column.title === '凭证'">
						<div class="image-w-h" v-if="record.imgArr.length > 0">
							<a-image :preview="false" :width="120" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
						</div>
				    </template>
					<template v-if="column.key === 'operation'">
						<div class="caozuo" v-if="record.status == 'wait' || record.status == 'fail'">
							<p @click="updateClick(2,record.id,record.content,index)">编辑</p>
							<!-- <a-popconfirm
							    title="确认是否申请支付?"
							    ok-text="确认"
							    cancel-text="取消"
							    @confirm="payClick(2,record.id,index)">
							    <p>申请支付</p>
							  </a-popconfirm> -->
							  <p @click="payClick(2,record.id,index)">申请支付</p>
						</div>
						<div class="caozuo2" v-else>
							<p>编辑</p>
							<p>申请支付</p>
						</div>
					</template>
			  </template>
			</a-table>
		</GModule>	
		<!-- 编辑 -->
		<a-modal v-model:visible="isUpdate" title="编辑" @ok="updateOk()">
			<a-form
				class="need-box"
			    :label-col="{ span: 6}"
			    :wrapper-col="{ span: 14}"
			    autocomplete="off"
				:scrollToFirstError='true'>
				<a-form-item label="完成内容">
				    <a-textarea placeholder="完成内容" style="height: 150px;" v-model:value="describe" />
				</a-form-item>
				<a-form-item label="凭证(选填)">
				    <a-upload
						name="file"
						v-model:file-list="fileList"
						:maxCount='8'
						list-type="picture-card"
						:customRequest ="customRequest"
						@remove="remove">
						<div class="addimg" v-if="fileList.length < 8">
							<plus-outlined />
							<p style="color: #999999;">点击上传图片</p>
						</div>
				    </a-upload>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</div>
</template>

<script>
	export default{
		data(){
			return{
				stateArr:[] ,
				stateIndex:0,
				columnsHe: [{
				    title: '期数',
				    width: 120,
				    dataIndex: 'periods',
				    fixed: 'left'
				  }, {
				    title: '完成内容',
				    width: 180,
				    dataIndex: 'content',
					ellipsis:true
				  }, {
				    title: '凭证',
				    width: 120,
				    dataIndex: 'images'
				  }, {
				    title: '状态',
				    width: 80,
				    dataIndex: 'status_text'
				  },  {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 80,
				}],
				data: [],
				columnsHe2: [{
				    title: '期数',
				    width: 120,
				    dataIndex: 'periods',
				    fixed: 'left'
				  }, {
				    title: '完成内容',
				    width: 180,
				    dataIndex: 'content',
					ellipsis:true
				  }, {
				    title: '凭证',
				    width: 120,
				    dataIndex: 'images'
				  }, {
				    title: '状态',
				    width: 80,
				    dataIndex: 'status_text'
				  },  {
				    title: '操作',
				    key: 'operation',
				    fixed: 'right',
				    width: 80,
				}],
				data2: [],
				info:{},
				fileList:[] ,// 上传图片file列表
				imgs:[] ,// 已上传的图片
				isUpdate:0,// 修改
				describe:'' ,// 完成内容
				updateIndex:0 ,// 修改的下标
				type:0 // 标识
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId  
			},
			proName(){
				return this.$store.state.proName  
			}
		},
		watch:{
			proId(val,oldvalue){
				if(val){
					this.getDetail() 
				}
			}
		},
		async created() {
			this.getDetail() 
		},
		methods:{
			async getDetail(){
				// 获取项目开发人员
				if(this.proId){
					let rr = await this.$utils.api.post({
						url:'/team/team_selectpage',
						data:{
							project_id: this.proId,
							label:'develop'
						},
						result:1
					})
					if(rr.code == 1){
						this.stateArr = rr.data.list
						if(this.stateArr.length > 0){
							// 初始化列表
							this.getList()
						}
					}
				}
			},
			handleMenuClick(e){
			    this.stateIndex = e.key
				this.getList()
			},
			async getList(){
				let rr = await this.$utils.api.post({
					url:'/contract/contract_info',
					data:{
						user_id:this.stateArr[this.stateIndex].id
					},
					result:1
				})
				if(rr.code == 1){
					 // 原始需求
					let a = rr.data.originals
					for(let i in a){
						if(a[i].images != null && a[i].images.length > 0){
							a[i].imgArr = a[i].images.toString().split(',')
						}else{
							a[i].imgArr=[]
						}
					}
					this.data = a

					// 新增变更需求
					let b = rr.data.appends
					for(let i in b){
						if(b[i].images != null && b[i].images.length > 0){
							b[i].imgArr = b[i].images.toString().split(',')
						}else{
							b[i].imgArr=[]
						}
					}
					this.data2 = b
					this.info = rr.data
					this.$store.commit('setStore',{ name:'history', data:{list:rr.data.history,type:'contract',id:0} })
				}
			},
			openSwiper(arr){
				// 弹出图片列表
				this.$store.commit('setSwiperArr',arr)
				this.$store.commit('setSwiper',1)
			},
			updateClick(type,id,content,index){
				// 编辑
				this.isUpdate = 1
				this.updateId = id
				this.updateIndex = index
				this.describe = content
				this.imgs = []
				this.fileList = []
				this.type = type
			},
			async customRequest (e) {
				// 执行图片上传
				let that = this
				let res = await this.$utils.api.upLoad(e)
				if(res){
					setTimeout(()=>{
						that.$utils.msgSuc('上传成功')
						// 上传成功修改文件状态
						that.fileList[that.fileList.length-1].status = 'done' 
						// 图片封面预览
						that.fileList[that.fileList.length-1].thumbUrl = res.data.fullurl 
						// 图片集合
						that.imgs.push(res.data.fullurl)
					},500)
				}
			},
			remove(e){
				// 删除图片
				this.imgs = this.imgs.filter((item) => {
				    return item != e.thumbUrl
				})
			},
			async updateOk(){
				//提交修改
			  if(this.describe == ''){
				  this.$utils.msgWar('请填写完成内容')
				  return
			  }
			  let info = {
				ids: this.updateId,
				content:this.describe,
				images:this.imgs.join(',')
			  }
			  let res = await this.$utils.api.post({
				url:'/contract/info_edit',
				data:info,
				result:1
			  })
			  if(res.code == 1){
				this.$utils.msgSuc('修改成功')
				this.isUpdate = 0
				if(this.type == 1){ // 基本合同情况
					this.data[this.updateIndex].content = this.describe
					this.data[this.updateIndex].imgArr = this.imgs
				}
				if(this.type == 2){
					// 变更合同情况
					this.data2[this.updateIndex].content = this.describe
					this.data2[this.updateIndex].imgArr = this.imgs
				}
				
			  }
					  
			},
			async payClick(type,id,index){
				// 申请支付
				let res = await this.$utils.api.post({
					url:'/contract/apply_pay',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('支付申请已提交')
					if(type == 1){
						this.data[index].status = 'review'
						this.data[index].status_text = '审核中'
					}else if(type == 2){
						this.data2[index].status = 'review'
						this.data2[index].status_text = '审核中'
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.time-box{
			display: flex;
			align-items: center;
			p{
				&:nth-child(1){
					margin-right: 15px;
				}
				&:nth-child(2){
					width: 80px;
					font-size: 12px;
					color: #999999;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 5px 10px;
				}
			}
		}
		.caozuo{
			display: flex;
			p{
				margin-right: 15px;
				font-size: 12px;
				color: #407cff;
			}
		}
		.caozuo2{
			display: flex;
			p{
				margin-right: 15px;
				font-size: 12px;
				color: #999999;
			}
		}
	}
</style>